<script>
export default {
    name: "JustTry",
    props: {
        imgNum: {
            type: Number,
            default: 1
        },
        serviceName: {
            type: String,
            default: ""
        },
        serviceSubtitle: {
            type: String,
            default: ""
        },
        buttonText: {
            type: String,
            default: "Попробовать"
        },
        buttonLinkToGeoeffect: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<template>
    <div id="just-try" class="just-try">
        <div class="just-try-text">
            <div class="just-try-title">{{ serviceName }}</div>
            <div
                v-if="serviceSubtitle"
                class="just-try-subtitle"
            >
                {{ serviceSubtitle }}
            </div>
        </div>
        <div
            :class="`just-try-note-${imgNum}`"
            class="just-try-note"
        ></div>
        <div class="just-try-button-container">
            <a
                v-if="buttonLinkToGeoeffect"
                class="just-try-button"
                href="https://system.geoeffect.ru/geoeffect_promo"
            >
                <button class="buttonDH">
                    <div class="buttonContentDH">
                        {{ buttonText }}
                    </div>
                </button>
            </a>
            <button
                v-else
                class="just-try-button"
                data-product="GeoEffect"
                data-vitrine="Геоэффект"
                data-btn-view="hidden"
                data-bo-hw-lead-button="true"
                data-bo-required-inn="true"
                data-bo-not-required="email"
                data-bo-subline=""
                :data-btn-text="buttonText"
            >
                <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/just-try.scss';
</style>